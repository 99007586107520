import { Stack } from '@mui/material';
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IResourcePlannerPeriodValueString } from 'src/apis/types/resourcePlannerAPI';
import { useIsSidePanelOpen } from 'src/components/layout/SidePanel';
import { Shimmer, Table, TCellIndentation } from 'src/components/ui-components';
import { stringToPascal } from 'src/utils/string';
import { RPProjectViewDisableExpandAllCount } from '../../constants';
import { applyParentPropsToChildren } from '../../helper/applyParentPropsToChildren';
import { getExpandedTableRowIds } from '../../helper/expandedRow';
import { getNotPlannedFontColor } from '../../helper/getNotPlannedFontColor';
import { RPProjectViewInitialExpandedRowsStateKey } from '../../localStorageKeys';
import { NewRTCell, NewRTRow, RPRow, RTColumn, RTRow } from '../../types/resourcePlanner';
import MemoizedRenderBodyCellColumn from '../MemoizedRenderBodyCellColumn';
import MemoizedRenderBodyCellPeriodColumn from '../MemoizedRenderBodyCellPeriodColumn/MemoizedRenderBodyCellPeriodColumn';
import MemoizedTableColGroupColumn from '../MemoizedTableColGroupColumn/MemoizedTableColGroupColumn';
import { NameColumnRenderer } from '../NameColumnRenderer';
import ResourceBar from '../ResourceBar';
import ToggleRowsButton from '../ToggleRowsButton';
import RenderBodyFirstColumn from './RenderBodyFirstColumn';

interface ResourceTableGroupedByProjectTableProps {
  columns: ColumnDef<RPRow>[];
  data: RPRow[];
  fetchNextPage: () => void;
  handleOnToggleRow: (row: RTRow) => void;
  isFetching: boolean;
  onCellValueChange: (row: RTRow, column: RTColumn, value: string) => void;
  reportingType: string;
  selectedViewOptions: Record<string, string>;
  setExpandedRows: (expandedIds?: string, rowsWithChange?: any[]) => void;
  unitType: string;
}

export const ResourceTableGroupedByProjectTable = ({
  columns,
  data,
  fetchNextPage,
  handleOnToggleRow,
  isFetching,
  onCellValueChange,
  reportingType,
  selectedViewOptions,
  setExpandedRows,
  unitType,
}: ResourceTableGroupedByProjectTableProps) => {
  const sidePanelIsOpen = useIsSidePanelOpen();

  const [expanded, setExpanded] = useState<ExpandedState>(() =>
    getExpandedTableRowIds(RPProjectViewInitialExpandedRowsStateKey),
  );

  const getShouldDisableExpandAll = () => {
    if (data && data.length >= RPProjectViewDisableExpandAllCount) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setExpanded(getExpandedTableRowIds(RPProjectViewInitialExpandedRowsStateKey));
  }, [data]);

  const projectList = useMemo(
    () =>
      data.map((d) => ({
        name: d.name,
        projectId: d.sourceReferenceId,
        workItemId: d.workItemId,
      })),
    [data],
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: (row: RTRow) => !!row.original.canExpand,
    getSubRows: (row) => {
      const children = row?.children && row?.children?.length > 0 ? row.children : [];
      return applyParentPropsToChildren(children, projectList);
    },
    manualExpanding: false,
    meta: {
      updateData: onCellValueChange,
    },
    onExpandedChange: setExpanded,
    state: {
      expanded,
    },
  });

  const { rows: tableRows } = table.getRowModel();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: tableRows.length,
    estimateSize: () => 40, // estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    // measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const virtualItems = rowVirtualizer.getVirtualItems();

  const memoizedTableRows = useMemo(
    () =>
      tableRows.map((row) => {
        const { startsAt, endsAt } = row.original;

        const newRow: NewRTRow = {
          ...row,
          allCells: row.getVisibleCells().map((cell) => ({
            ...cell,
            colSpan: false,
            ignoreRender: false,
          })),
          colSpanCounter: 0,
        };

        if (
          newRow.original.hierarchyType === 'workitem' &&
          (reportingType === 'availability' || unitType === 'percentages') &&
          startsAt &&
          endsAt
        ) {
          const startsAtDate = DateTime.fromISO(startsAt);
          const endsAtDate = DateTime.fromISO(endsAt);

          newRow.allCells = row.getVisibleCells().map((cell) => {
            const updatedCell: NewRTCell = {
              ...cell,
              colSpan: false,
              ignoreRender: false,
            };

            const columnIsPeriod = cell.column.columnDef.meta?.isPeriod;

            if (columnIsPeriod) {
              const columnStartsAtDate = DateTime.fromISO(cell.column.columnDef.meta?.startsAt!);
              const columnEndssAtDate = DateTime.fromISO(cell.column.columnDef.meta?.endsAt!);
              if (
                (columnStartsAtDate >= startsAtDate && columnStartsAtDate <= endsAtDate) ||
                (columnEndssAtDate >= startsAtDate && columnEndssAtDate <= endsAtDate) ||
                (columnStartsAtDate <= startsAtDate && columnEndssAtDate >= endsAtDate)
              ) {
                if (newRow.colSpanCounter === 0) {
                  newRow.colSpanCounter = 1;
                  updatedCell.colSpan = true;
                } else {
                  newRow.colSpanCounter += 1;
                  updatedCell.ignoreRender = true;
                }
              }
            }

            return updatedCell;
          });
        }
        return newRow;
      }),
    [tableRows, unitType, reportingType],
  );

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRef?: HTMLDivElement | null) => {
      if (containerRef) {
        const { scrollHeight, scrollTop, clientHeight } = containerRef;
        if (isFetching) {
          return;
        }
        // once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
        if (scrollHeight - scrollTop - clientHeight < 50) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching],
  );

  // a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  return (
    <div
      className="container"
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      ref={tableContainerRef}
      style={{
        overflow: 'auto', // our scrollable table container
        position: 'relative', // needed for sticky header
        height: 'calc(100vh - 404px)', // should be a fixed height
        width: 'calc(100vw - 96px)',
      }}
    >
      {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
      <Table
        compact
        data-automation-id="ResourceViewTable"
        sidePanelIsOpen={sidePanelIsOpen}
        stickyFirstColumn
        stickyLastColumn
        // style={{ display: 'grid' }}
        tableHover
      >
        <Table.ColGroup>
          {table
            .getHeaderGroups()
            .map((headerGroup) =>
              headerGroup.headers.map((header) => (
                <MemoizedTableColGroupColumn key={`colGroup_col_${header.column.columnDef.id}`} />
              )),
            )}
        </Table.ColGroup>

        <Table.Head
        // style={{
        //   display: 'grid',
        //   position: 'sticky',
        //   top: 0,
        //   zIndex: 1,
        // }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={`headerGroup_row_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Table.Header
                  alignment={header.column.columnDef.meta?.alignment}
                  id={`tableHeaderCell${stringToPascal(header.column.id)}`}
                  key={`header_${header.column.columnDef.id}`}
                >
                  {header.column.columnDef.id === 'name' ? (
                    <Stack alignItems="center" direction="row" key={header.id}>
                      <ToggleRowsButton
                        disableExpandAll={getShouldDisableExpandAll()}
                        handleOnToggleRow={handleOnToggleRow}
                        isGroupedByProject
                        rows={table.getRowModel().flatRows}
                        setExpandedRows={setExpandedRows}
                        viewStorageKey={RPProjectViewInitialExpandedRowsStateKey}
                      />
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Stack>
                  ) : (
                    <span title={header.column.columnDef.meta?.title}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </span>
                  )}
                </Table.Header>
              ))}
            </Table.Row>
          ))}
        </Table.Head>

        <Table.Body
        // style={{
        //   display: 'grid',
        //   height: `${rowVirtualizer.getTotalSize()}px`, // tells scrollbar how big the table is
        //   position: 'relative', // needed for absolute positioning of rows
        // }}
        >
          {memoizedTableRows.map((row) => (
            <Table.Row
              // data-index={row.index} // needed for dynamic row height measurement
              key={`body_row_${row.id}`}
              // ref={(node: HTMLTableRowElement) => rowVirtualizer.measureElement(node)} // measure dynamic row height
              // style={{
              //   display: 'flex',
              //   position: 'absolute',
              //   transform: `translateY(${row.start}px)`, // this should always be a `style` as it changes on scroll
              //   width: '100%',
              // }}
            >
              {row.allCells.map((cell) => {
                if (cell.ignoreRender) {
                  return null;
                }

                if (row.original.isLoading) {
                  return (
                    <Table.Cell
                      alignment={cell.column.columnDef.meta?.alignment}
                      dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                      indent={
                        cell.column.columnDef.id === 'name'
                          ? (row.depth as TCellIndentation)
                          : (0 as TCellIndentation)
                      }
                      key={`row${row.id}col${cell.column.columnDef.id}`}
                      type={cell.column.columnDef.meta?.type}
                      valign="bottom"
                    >
                      <Shimmer height="quarter" />
                    </Table.Cell>
                  );
                }

                if (cell.column.columnDef.id === 'name') {
                  return (
                    <RenderBodyFirstColumn
                      alignment="left"
                      depth={row.depth as TCellIndentation}
                      key={`row${row.id}col${cell.column.columnDef.id}`}
                      rowId={row.id}
                    >
                      <NameColumnRenderer
                        getRowModel={table.getRowModel}
                        handleOnToggleRow={handleOnToggleRow}
                        row={row}
                        value={cell.getValue<string>()}
                      />
                    </RenderBodyFirstColumn>
                  );
                }

                if (cell.colSpan) {
                  return (
                    <Table.Cell
                      colSpan={row.colSpanCounter}
                      dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                      key={`row_cell_${cell.id}_${cell.column.columnDef.id}`}
                    >
                      <ResourceBar depth={row.depth} />
                    </Table.Cell>
                  );
                }

                if (
                  (row.getCanExpand() &&
                    cell.column.columnDef.meta?.editable &&
                    (reportingType === 'availability' || unitType === 'percentages')) ||
                  (row.original.resourceType === 'Unknown' &&
                    (reportingType === 'availability' || unitType === 'percentages') &&
                    row.subRows.length < 1 &&
                    cell.column.columnDef.meta?.editable)
                ) {
                  return (
                    <Table.Cell
                      dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                      key={`row_cell_${cell.id}_${cell.column.columnDef.id}`}
                    />
                  );
                }

                if (cell.column.columnDef.id?.startsWith('periodCol_')) {
                  const periodColumnIdSubString = cell.column.columnDef.id.substring(
                    cell.column.columnDef.id.indexOf('_') + 1,
                    cell.column.columnDef.id.length,
                  );

                  const periodCellData: IResourcePlannerPeriodValueString = cell.column.columnDef
                    .meta?.editable
                    ? row.original.values[periodColumnIdSubString]
                    : (cell.getValue() as IResourcePlannerPeriodValueString);

                  const isCellEditable =
                    (selectedViewOptions['reporting-types'] !== 'availability' &&
                      row.original.editable &&
                      cell.column.columnDef?.meta?.editable &&
                      periodCellData?.editable) ||
                    false;

                  return (
                    <MemoizedRenderBodyCellPeriodColumn
                      alignment={cell.column.columnDef.meta?.alignment}
                      column={cell.column}
                      dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                      editable={cell.column.columnDef.meta?.editable}
                      handleOnCellValueChanged={table.options.meta?.updateData}
                      heatmapCode={periodCellData?.heatmapCode}
                      isCellEditable={isCellEditable}
                      key={`row${row.id}col${cell.column.columnDef.id}`}
                      reportingType={selectedViewOptions['reporting-types']}
                      row={row}
                      type={cell.column.columnDef.meta?.type}
                      unitType={unitType}
                    />
                  );
                }

                return (
                  <MemoizedRenderBodyCellColumn
                    alignment={cell.column.columnDef.meta?.alignment}
                    cellValue={cell.getValue<string | undefined>()}
                    dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                    editable={cell.column.columnDef.meta?.editable}
                    fontColor={
                      cell.column.columnDef.id === 'notPlanned'
                        ? getNotPlannedFontColor(cell.getValue<number>())
                        : ''
                    }
                    key={`row${row.id}col${cell.column.columnDef.id}`}
                    type={cell.column.columnDef.meta?.type}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </MemoizedRenderBodyCellColumn>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};
