import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  IResourcePlannerItem,
  IResourcePlannerPeriodColumn,
  IResourcePlannerResult,
} from 'src/apis/types/resourcePlannerAPI';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { getExpandedRowOriginalIds } from 'src/screens/ResourcePlanner/helper/expandedRow';
import { RPProjectViewInitialExpandedRowsStateKey } from 'src/screens/ResourcePlanner/localStorageKeys';
import { postFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import {
  GROUP_BY_PROJECT_KEY,
  GROUP_BY_PROJECT_MSW_STATUS_KEY,
} from './getGroupByProjectAPI.constants';

const GROUP_BY_PROJECT_URL_PATH = '/api/v2/resource-planner/partial-group-by-work-item-first-load';

const resourcePlannerFetch = (
  filters: string,
  params: Record<string, string>,
  expandedRowIds?: string,
): Promise<IResourcePlannerResult> =>
  postFetch({
    path: `${GROUP_BY_PROJECT_URL_PATH}${concatQuerys(
      Object.keys(params).map((key) => ({
        param: key.replaceAll('-', ''),
        value:
          // we need the hyphens in dates
          key === 'period-starts-at' || key === 'period-ends-at'
            ? params[key]
            : params[key].replaceAll('-', ''),
      })),
    )}`.concat(filters),
    key: GROUP_BY_PROJECT_MSW_STATUS_KEY,
    body: JSON.stringify({ expandId: expandedRowIds }),
  });

const PARTIAL_GROUP_BY_PROJECT_URL_PATH = '/api/v2/resource-planner/partial-group-by-work-item';
export const PARTIAL_GROUP_BY_PROJECT_MSW_STATUS_KEY = 'GetPartialGroupByProjectAPI';

const partialResourcePlannerFetch = (
  filters: string,
  params: Record<string, string>,
  expandId: string,
  expandType: IResourcePlannerItem['workItemType'],
): Promise<IResourcePlannerResult> =>
  postFetch({
    path: `${PARTIAL_GROUP_BY_PROJECT_URL_PATH}${concatQuerys(
      Object.keys(params).map((key) => ({
        param: key.replaceAll('-', ''),
        value:
          // we need the hyphens in dates
          key === 'period-starts-at' || key === 'period-ends-at'
            ? params[key]
            : params[key].replaceAll('-', ''),
      })),
    )}`.concat(filters),
    key: PARTIAL_GROUP_BY_PROJECT_MSW_STATUS_KEY,
    body: JSON.stringify({ expandId, expandType }),
  });

export const getTranslatedStatus = (
  result: IResourcePlannerItem[],
  t: TFunction<'resourcePlanner', undefined>,
): IResourcePlannerItem[] =>
  result.map(({ status, children, ...child }) => ({
    status,
    translatedStatus: t(status),
    children: children ? getTranslatedStatus(children, t) : undefined,
    ...child,
  }));

export const getAllResourceIds = (result: IResourcePlannerItem[]): string[] =>
  result.map(({ resourceId }) => resourceId);

export const useGetGroupByProject = (
  { selectedFilterList }: Pick<IFilterStoreProps, 'selectedFilterList'>,
  params?: Record<string, string>,
  rpLoading?: boolean,
) => {
  const { t } = useTranslation('resourcePlanner');
  const take = 50;
  const items = Object.keys(selectedFilterList);
  const filtersParam = `${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `${item}Ids`,
        value: selectedFilterList[item].values.map((value) => value.value).toString(),
        isAppended: true,
      })),
  )}${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `Is${item}Inclusive`,
        value: selectedFilterList[item].isInclude ? 'true' : 'false',
        isAppended: true,
      })),
  )}`;
  const { data, ...restProps } = useInfiniteQuery<IResourcePlannerResult>(
    [GROUP_BY_PROJECT_KEY],
    async ({ pageParam = 0 }) => {
      getExpandedRowOriginalIds(RPProjectViewInitialExpandedRowsStateKey);
      const reqParams: Record<string, string> = {
        ...params,
        skip: String(pageParam * take),
        take: String(take),
      };

      const response = await resourcePlannerFetch(filtersParam, reqParams ?? {}, '');

      if (response?.model?.properties?.children) {
        response.model.properties.children = getTranslatedStatus(
          response.model.properties.children,
          t,
        );
      }

      return response;
    },
    {
      // any previous data will be kept when fetching new data because the query key changed.
      enabled: !rpLoading,
      getNextPageParam: (_lastGroup, groups) => groups.length,
      // initialPageParam: 0,
      keepPreviousData: true,
      // placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const latestPage = data?.pages[(data?.pages?.length ?? 1) - 1];
  const latestPageChildrenLength = latestPage?.model.properties.children.length;

  // flatten the array of arrays from the useInfiniteQuery hook
  const properties = data?.pages?.flatMap((page) => page.model.properties) ?? [];
  const children = properties.flatMap((property) => property.children);
  const periods = properties.reduce((_, b) => b.periods, [] as IResourcePlannerPeriodColumn[]);
  const responseType = properties.reduce((_, b) => b.responseType, 'GroupByWorkItem');
  const viewOptions = properties.reduce((a, b) => ({ ...a, ...b.viewOptions }), {
    'grouped-by': '',
    'included-work-items': '',
    'reporting-types': '',
    'period-types': '',
    'unit-types': '',
  });

  return {
    children,
    isEmpty: children.length <= 0,
    isNextPageAvailable: latestPageChildrenLength === 50,
    periods,
    responseType,
    viewOptions,
    ...restProps,
  };
};
export const useGetPartialGroupByProject = (
  { selectedFilterList }: Pick<IFilterStoreProps, 'selectedFilterList'>,
  params?: Record<string, string>,
) => {
  const { t } = useTranslation('resourcePlanner');
  const items = Object.keys(selectedFilterList);
  const filtersParam = `${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `${item}Ids`,
        value: selectedFilterList[item].values.map((value) => value.value).toString(),
        isAppended: true,
      })),
  )}${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `Is${item}Inclusive`,
        value: selectedFilterList[item].isInclude ? 'true' : 'false',
        isAppended: true,
      })),
  )}`;

  return useMutation({
    mutationFn: async ({
      rowId,
      rowType,
    }: {
      rowId: string;
      rowType: IResourcePlannerItem['workItemType'];
    }) => {
      const response = await partialResourcePlannerFetch(
        filtersParam,
        params ?? {},
        rowId,
        rowType,
      );

      if (response?.model?.properties?.children) {
        response.model.properties.children = getTranslatedStatus(
          response.model.properties.children,
          t,
        );
      }

      return response;
    },
  });
};
