/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo, ReactNode } from 'react';
import { THorizontalAlignment } from 'src/assets/variables/alignment';
import { Table, TCellText } from 'src/components/ui-components/Table';

interface IMemoizedRenderBodyCellColumn {
  cellValue: string | undefined;
  children: ReactNode;
  alignment?: THorizontalAlignment;
  dividerBorderLeft?: boolean;
  editable?: boolean;
  endsAt?: string;
  fontColor?: string;
  startsAt?: string;
  style?: React.CSSProperties;
  type?: TCellText;
}

const MemoizedRenderBodyCellColumn = memo(
  ({
    alignment,
    cellValue,
    children,
    dividerBorderLeft,
    editable,
    endsAt,
    fontColor,
    startsAt,
    style,
    type,
  }: IMemoizedRenderBodyCellColumn) => (
    <Table.Cell
      alignment={alignment}
      cellFontColor={fontColor}
      dividerBorderLeft={dividerBorderLeft}
      hasOverflow={editable}
      style={style}
      type={type}
    >
      {children}
    </Table.Cell>
  ),
  (prevProp, nextProp) => {
    const isSameCellValue = prevProp.cellValue === nextProp.cellValue;
    const isSameStartsAt = prevProp.startsAt === nextProp.startsAt;
    const isSameEndsAt = prevProp.endsAt === nextProp.endsAt;

    return isSameCellValue && isSameStartsAt && isSameEndsAt;
  },
);

export default MemoizedRenderBodyCellColumn;
